import { Box } from "@mui/material";
import * as React from "react";

const Logo = ({ width = "62.54px" }) => (
  <Box sx={{ height: "40px", width: width }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 62.54 40"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="17.09"
          x2="51.43"
          y1="57.29"
          y2="-35.92"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f08f24" />
          <stop offset=".13" stopColor="#ef8624" />
          <stop offset=".5" stopColor="#ed7325" />
          <stop offset=".76" stopColor="#ed6d26" />
        </linearGradient>
        <filter id="drop-shadow-2" filterUnits="userSpaceOnUse">
          <feOffset dx="2" dy="5" />
          <feGaussianBlur result="blur" stdDeviation="10" />
          <feFlood floodColor="#fff" floodOpacity=".75" />
          <feComposite in2="blur" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <path
        fill="url(#linear-gradient)"
        strokeWidth="0"
        d="M51.64 32.92c-10.3 1.03-11.13 1.04-30.84.72-4.17-.07-9.04 7.05-13.04 6.3-1.35-.25.19-5.94-.75-6.93-.3-.32-4.57-.09-5.96-4.19-.87-2.53-1.54-9.51-.58-14.99.15-.85 1.05-7.21 2.13-7.91.83-.54 3.08 1.59 6.14 1.46 1.28-.06 3.68-5.55 4.71-5.62.92-.06 6.13 3.6 8.72 3.47C23.7 5.15 27.99.41 29.03.02c.74-.28 5.08 5.51 7 5.43C37.8 5.37 43.69.8 44.06.82c1.39.05 5.61 5.57 8.42 5.75 3.17.2 4.63-3.15 5.37-3.05 1.19.16 3.17 5.34 3.9 8.6.46 2.03 1.01 4.84.79 8.85-.31 5.7.31 10.86-10.9 11.98Z"
      />
      <path
        fill="white"
        d="M17.41 23.36c0 1.25.12 2.41-.81 3.38-1.12 1.16-3.06 1.62-4.61 1.64-2.74.04-5.4-1.25-7.01-3.48-3.31-4.61-1.04-11.07 4.33-12.78 1.86-.59 7-.97 7.79 1.54.64 2.04-2.06 1.55-3.11 1.29-1.58-.39-3.15-.51-4.62.3-4.59 2.5-2.8 9.7 1.57 10.25.72.09 2.06-.02 2.95-.2 1.2-.24 1.09-.53 1.09-1.82 0-1.1-.38-1.66-1.48-1.38-1.28.33-2.08.61-2.17-1.27.05-1.7 2.65-1.6 3.85-1.41 2.3.37 2.11 1.99 2.23 3.92ZM19.52 20.71c0-2.14-.78-5.77 1.1-7.31 1.52-1.25 4.27-1.1 5.94-.3 2.51 1.2 3.61 4.03 2.95 6.76-.56 2.32-2.32 2.74-2.3 3.32.02.45 1.05 1.44 1.34 1.84.94 1.3 3.35 3.9.41 4.39-2.07.35-3.66-4.3-5.03-5.58-2.11-1.98-.49 3.62-2.56 3.99-2.79.51-1.85-5.75-1.85-7.11Zm3.07-2.34c0 1.47 0 2.5 1.9 2.2 1.23-.19 2.24-1.1 2.26-2.4.03-1.28-.99-2.39-2.21-2.68-2.01-.48-1.96 1.46-1.96 2.88ZM58.89 14.1c-.75 1.55-2.03.45-4.62.79-2.83.37-2.55 1.16-2.54 1.93.02 1.84.61 1.19 2.53 1.1 2.62-.13 2.64.1 2.69 1.24.05 1.23-.4 1.25-2.49 1.21-3.17-.07-3.13-.33-2.85 2.44.21 2.08 1.01 1.73 2.77 1.56 1.86-.18 3.77-1.86 4.51-.72 1.1 1.71-.73 3.32-4.79 3.37-1.69.02-3.41-.39-4-.97-2-1.96-1.43-6.47-1.3-9.03.2-3.91 2.01-4.52 5.86-4.74 3.06-.18 4.74.78 4.23 1.82ZM37.24 19.99c0-3.63-.84-7.46 4.27-7.49 3.82-.02 6.49 3.82 5.33 7.39-.5 1.56-1.69 2.68-3.24 3.19-.72.23-2.36-.03-2.88.37-.53.4-.36 1.09-.36 1.77 0 1.23.32 5.08-2.13 3.69-1.86-1.06-1-5.81-1-7.58V20Zm6.82-1.95c.01-1.25-.92-2.44-2.2-2.58-1.6-.17-1.51.91-1.5 2.2 0 1.35-.43 2.89 1.3 2.87 1.33-.02 2.37-1.21 2.4-2.49ZM33.02 13.21c1.37-.11 2.1 0 2.04 7.74-.05 7.09 0 7.12-1.73 7.24-1.37.25-1.48-3.46-1.51-7.24s.02-7.65 1.21-7.74ZM33.11 8.65c-1.43.15-2.31 2.82-.22 3.02s2.15-.93 2.17-1.57-.32-1.62-1.95-1.45Z"
        className="cls-2"
      />
    </svg>
  </Box>
);
export default Logo;
