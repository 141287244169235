// In your Waitlist.js component file

import React, { useState } from "react";
import Header from "../header/Header";
import { Box, Container } from "@mui/material";
import Hero from "./Hero";
import Footer from "../footer/Footer";

function Waitlist() {
  return (
    <>
      <Header />
      <Container>
        <Hero />
      </Container>
      <Footer />
    </>
  );
}

export default Waitlist;
