import React, { useState } from "react";
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
  Container,
} from "@mui/material";
import { CircleOutlined } from "@mui/icons-material";
import AppImage from "../../images/App.png"; // Ensure the path is correct
import Header from "../header/Header";
import Footer from "../footer/Footer";

const Install = () => {
  const [platform, setPlatform] = useState("ios");

  const instructions = {
    ios: [
      {
        title: "Navigate to Gripe on Safari",
        description: "Stay on this page, you're in the right place!",
      },
      {
        title: "Use Share Button",
        description:
          "Tap the Share button located at the bottom center of the browser",
      },
      {
        title: "Add to Home Screen",
        description:
          "Scroll down in the share options and tap 'Add to Home Screen'.",
      },
    ],
    android: [
      {
        title: "Navigate to Gripe in Chrome",
        description: "Stay on this page, you're in the right place!",
      },
      {
        title: "Access Menu",
        description:
          "Tap the menu button in the upper right corner of the screen.",
      },
      {
        title: "Add to Home Screen",
        description: "Select 'Add to Home screen' from the menu.",
      },
    ],
  };

  // Handle platform switch
  const handlePlatformChange = (event, newPlatform) => {
    if (newPlatform !== null) {
      setPlatform(newPlatform);
    }
  };

  return (
    <>
      <Header />
      <Container>
        <Box sx={{ p: 2 }}>
          <Typography variant="h5" align="center" sx={{ mb: 2 }}>
            Install as a Web App
          </Typography>
          <Typography sx={{ maxWidth: "800px", mx: "auto", mb: 4 }}>
            Our web app is the quickest way to get Gripe on your phone. While we
            will eventually develop a native app for an even better experience,
            installing the web app now gives everyone the best of both worlds.
          </Typography>

          <ToggleButtonGroup
            value={platform}
            exclusive
            onChange={handlePlatformChange}
            aria-label="Platform"
            sx={{
              mb: 2,
              display: "flex",
              justifyContent: "center",
              textTransform: "none",
            }}
          >
            <ToggleButton
              value="ios"
              aria-label="ios"
              sx={{ textTransform: "none", minWidth: "100px" }}
            >
              iOS
            </ToggleButton>
            <ToggleButton
              value="android"
              aria-label="android"
              sx={{ textTransform: "none", minWidth: "100px" }}
            >
              Android
            </ToggleButton>
          </ToggleButtonGroup>

          <Box
            sx={{
              display: "flex",
              position: "relative",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "center",
              alignItems: "flex-start",
              gap: 2,
              // mt: { xs: 1, sm: 10 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                maxWidth: "600px",
              }}
            >
              <List>
                {instructions[platform].map((step, index) => (
                  <ListItem key={index}>
                    <ListItemIcon sx={{ mr: 2 }}>
                      <Badge
                        badgeContent={index + 1}
                        color="primary"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        sx={{
                          ".MuiBadge-badge": {
                            width: 32,
                            height: 32,
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1rem",
                            transform: "scale(1) translate(50%, -50%)",
                          },
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${step.title}`}
                      secondary={step.description}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: { xs: "100%", sm: "300px" },
                justifyContent: "center",
              }}
            >
              <img
                src={AppImage}
                alt="Hero"
                style={{
                  width: "300px",
                  height: "auto", // fixed height
                  objectFit: "cover", // ensures the image covers the box area
                }}
              />
            </Box>
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default Install;
