// -- src/firebase.js
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDBc7fLOFkBklt681HBKfmdZJ79yRVq8P8",
  authDomain: "gripe-f63e3.firebaseapp.com",
  projectId: "gripe-f63e3",
  storageBucket: "gripe-f63e3.appspot.com",
  messagingSenderId: "306432753325",
  appId: "1:306432753325:web:a04962556eb08ec8b65024",
  measurementId: "G-R3JVL6B7HP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Firebase that can also be emulated
let auth, db, functions, storage;

if (process.env.NODE_ENV === "development") {
  // Initialize services with emulator in development
  auth = getAuth();
  connectAuthEmulator(auth, "http://localhost:9099/");
  db = getFirestore();
  connectFirestoreEmulator(db, "localhost", 8080);
  functions = getFunctions(app);
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  storage = getStorage();
  connectStorageEmulator(storage, "127.0.0.1", 9199);
} else {
  // Initialize services normally for production
  auth = getAuth();
  db = getFirestore();
  functions = getFunctions(app);
  storage = getStorage();
}

export { app, analytics, auth, functions, db, storage };
