import "./App.css";

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Waitlist from "./components/waitlist/Waitlist";
import Install from "./components/install/Install";
import About from "./components/about/About";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Waitlist />} />

        <Route path="/waitlist" element={<Waitlist />} />
        {/* <Route path="/about/" element={<About />} /> */}
        <Route path="/install" element={<Install />} />
        <Route path="/about" element={<About />} />

        {/* Add other routes here as needed */}
      </Routes>
    </Router>
  );
}

export default App;
