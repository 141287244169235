import React from "react";
import { Box, Grid, Typography, Link, Divider } from "@mui/material";
import Logo from "../../SVGs/Logo";

// Example array structure for footer links
const footerLinks = [
  {
    title: "Product",
    links: [
      { text: "Link 1", href: "/link1" },
      { text: "Link 2", href: "/link2" },
      // Add more links as needed
    ],
  },
  {
    title: "Resources",
    links: [
      { text: "Link 3", href: "/link3" },
      { text: "Link 4", href: "/link4" },
      // Add more links as needed
    ],
  },
  {
    title: "Company",
    links: [
      { text: "Link 5", href: "/link5" },
      { text: "Link 6", href: "/link6" },
      // Add more links as needed
    ],
  },
];

function Footer() {
  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: "#333",
        boxSizing: "border-box",
        color: "white",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          {/* Replace with your logo */}
          <Logo width="150px"/>
          <Typography variant="h6" fontWeight={"500"} sx={{mt: 7}}>
            A place for your problems
          </Typography>
        </Grid>
        {footerLinks.map((column) => (
          <Grid item xs={6} sm={3} key={column.title}>
            <Typography variant="subtitle1" gutterBottom>
              {column.title}
            </Typography>
            {column.links.map((link) => (
              <Typography key={link.text}>
                <Link href={link.href} color="inherit">
                  {link.text}
                </Link>
              </Typography>
            ))}
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ my: 2 }} color="gray" />
      <Typography
        variant="body2"
        color="white"
        align="center"
      >
        © {new Date().getFullYear()} Gripe, LLC. All rights reserved.
      </Typography>
    </Box>
  );
}

export default Footer;
