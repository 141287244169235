import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SettingsIcon from "@mui/icons-material/Settings"; // This is a gear icon in MUI
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Divider,
  Skeleton,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { db, functions } from "../../firebase";
import ResponsiveDialog from "../custom/ResponsiveDialog";
import styled from "@emotion/styled";

const SpinningIcon = styled(SettingsIcon)`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  animation: spin 5s linear infinite;
`;

function WaitlistDialog() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [waitlistNumber, setWaitlistNumber] = useState(null);
  const [referralCount, setReferralCount] = useState(null);
  const [uniqueId, setUniqueId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [referrerId, setReferrerId] = useState(null);

  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

  useEffect(() => {
    let unsubscribe = () => {};

    // Set initial state from localStorage
    const waitlistInfo = localStorage.getItem("waitlistInfo");
    if (waitlistInfo) {
      const { email, waitlistNumber, uniqueId } = JSON.parse(waitlistInfo);
      setEmail(email);
      setWaitlistNumber(waitlistNumber);
      setUniqueId(uniqueId);
    }

    // Parse the URL for a referral ID
    const queryParams = new URLSearchParams(window.location.search);
    const refId = queryParams.get("ref");
    if (refId) {
      setReferrerId(refId);
    }

    // Subscribe to Firestore updates if uniqueId is available
    if (uniqueId) {
      const waitlistRef = doc(db, "waitlist", uniqueId);
      unsubscribe = onSnapshot(
        waitlistRef,
        (docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            setWaitlistNumber(data.waitlistNumber);
            setReferralCount(data.referralCount || null);
          } else {
            console.log("No such document!");
          }
        },
        (error) => {
          console.error("Error fetching waitlist data:", error);
        }
      );
    }

    setIsFetching(false);

    return () => unsubscribe(); // Cleanup on unmount or uniqueId change
  }, [uniqueId]);

  const handleSubmit = async () => {
    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }
    setIsLoading(true);
    setEmailError("");

    const addToWaitlist = httpsCallable(functions, "addToWaitlist");
    try {
      const result = await addToWaitlist({ email, referrerId }); // Pass referrerId
      setWaitlistNumber(result.data.waitlistNumber);
      setUniqueId(result.data.uniqueId);
      setIsLoading(false);
      setDialogOpen(true);

      localStorage.setItem(
        "waitlistInfo",
        JSON.stringify({
          email: email,
          waitlistNumber: result.data.waitlistNumber,
          uniqueId: result.data.uniqueId,
        })
      );
    } catch (error) {
      console.error("Error adding to waitlist:", error);
      setIsLoading(false);
    }
  };

  const handleCopyLink = () => {
    const referralLink = `https://gripespot.com/waitlist?ref=${uniqueId}`;
    navigator.clipboard.writeText(referralLink);
  };

  if (isFetching)
    return (
      <Box sx={{ gap: 1, my: 2 }}>
        <Skeleton variant="rounded" width="100%" height={56} />
      </Box>
    );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 1,
          my: 2,
        }}
      >
        {waitlistNumber === null ? (
          <>
            <TextField
              fullWidth
              label="Email Address"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="name@email.com"
              disabled={isLoading}
              error={!!emailError}
              helperText={emailError}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{ minWidth: "150px", textTransform: "none", height: "56px" }}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <Typography variant="body1">Join</Typography>
              )}
            </Button>
          </>
        ) : (
          <Button
            variant="outlined"
            onClick={() => setDialogOpen(true)}
            sx={{ height: "56px", minWidth: "200px", textTransform: "none" }}
          >
            You're #{waitlistNumber} in line
          </Button>
        )}
      </Box>

      <ResponsiveDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={"You're on the waitlist!"}
        width={"400px"}
      >
        <DialogContent>
          <Typography
            align="center"
            variant="subtitle1"
            fontSize={"1.5rem"}
            fontWeight={"600"}
            sx={{ mt: 1 }}
            color="primary"
          >
            Your current position is #{waitlistNumber}
          </Typography>
          <Typography
            align="center"
            alignItems={"center"}
            sx={{
              color: "gray", // or a specific gray color code like '#888' or 'rgba(128, 128, 128, 0.8)'
            }}
          >
            As we speak, we are building awesome new features for the community.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
            <SpinningIcon />
          </Box>
          <Typography
            align="center"
            variant="subtitle1"
            fontSize={"1.25rem"}
            fontWeight={"600"}
            sx={{ mt: 2 }}
          >
            Want to get early access?
          </Typography>
          <Typography
            align="center"
            sx={{
              color: "gray", // or a specific gray color code like '#888' or 'rgba(128, 128, 128, 0.8)'
            }}
          >
            Invite your friends and move up 10 spots!
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2,
            }}
          >
            <TextField
              fullWidth
              value={`https://gripespot.com/waitlist?ref=${uniqueId}`}
              InputProps={{
                endAdornment: (
                  <Button onClick={handleCopyLink}>
                    <ContentCopyIcon />
                  </Button>
                ),
              }}
              sx={{
                backgroundColor: "#f5f5f5", // Light gray background
              }}
              variant="outlined"
            />
          </Box>
          <Typography align="center" sx={{ my: 2 }}>
            {referralCount != null ? (
              <>
                You invited{" "}
                <strong>
                  {referralCount} {referralCount === 1 ? "friend" : "friends"}
                </strong>{" "}
                to sign up!
              </>
            ) : (
              <>
                <strong>0 invites</strong> have joined. Check back soon!
              </>
            )}
          </Typography>
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}

export default WaitlistDialog;
