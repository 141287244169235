import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  Box,
  useTheme,
  useMediaQuery,
  Stack,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../../SVGs/Logo";

function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [appBarMargin, setAppBarMargin] = useState("8px");
  const [appBarBackground, setAppBarBackground] = useState("hsl(21,84%,54%)");

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
    setAppBarMargin(open ? "16px" : "8px");
    setAppBarBackground(open ? "white" : "hsl(21,84%,54%)");
  };

  const menuItems = (
    <Box
      sx={{
        display: "flex",
        mx: 2,
        flexDirection: { xs: "column", sm: "row" },
        gap: 1,
        my: 1,
      }}
    >
      <Button
        href="/install"
        variant="text"
        sx={{ height: "50px", px: 3, textTransform: "none", cursor: "pointer" }}
      >
        <Typography variant="body1" fontWeight="500">
          App
        </Typography>
      </Button>
      <Button
        href="/about"
        variant="text"
        sx={{ height: "50px", px: 3, textTransform: "none", cursor: "pointer" }}
      >
        <Typography variant="body1" fontWeight="500">
          About
        </Typography>
      </Button>
      <Button
        href="/waitlist"
        variant="contained"
        sx={{ height: "50px", px: 3, textTransform: "none", cursor: "pointer" }}
      >
        <Typography variant="body1" fontWeight="500">
          Join Waitlist
        </Typography>
      </Button>
    </Box>
  );

  return (
    <AppBar
      sx={{
        position: "sticky",
        top: { xs: appBarMargin, md: "0px" },
        zIndex: "9999",
        borderRadius: { xs: "30px", md: "0px" },
        mx: { xs: appBarMargin, md: "0px" },
        mt: { xs: appBarMargin, md: "0px" },
        mb: 1,

        boxSizing: "border-box",
        background: { xs: appBarBackground, md: "white" },
        boxShadow: {
          xs: drawerOpen
            ? "none"
            : "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          md: "none",
        },
        width: "auto",
        transition: "all 0.2s ease-in-out", // Smooth transition for margin
      }}
    >
      <Toolbar
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        onClick={isMobile ? toggleDrawer(!drawerOpen) : undefined}
      >
        <Logo />
        {!isMobile && menuItems}
        {isMobile && (
          <IconButton
            color={drawerOpen ? "hsl(21,84%,54%)" : "inherit"}
            aria-label="open drawer"
            edge="end"
            onClick={toggleDrawer(!drawerOpen)}
          >
            {drawerOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        )}
      </Toolbar>
      {isMobile && (
        <Drawer
          anchor="top" // Change to 'right' if you prefer it on the right side
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          PaperProps={{ sx: { borderRadius: "38px", mx: 1, mt: 1 } }} // Adjust top based on AppBar height
        >
          <Box sx={{ height: { xs: "56px", sm: "64px" }, mb: 1 }} />

          {menuItems}
        </Drawer>
      )}
    </AppBar>
  );
}

export default Header;
