import React from "react";
import { Box, Typography, Container } from "@mui/material";
import AlexPhoto from "../../images/AlexAvatar.jpg"; // Ensure the path is correct
import Header from "../header/Header";
import Footer from "../footer/Footer";

const About = () => {
  return (
    <>
      <Header />
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "800px",
            mx: "auto",
            gap: 2,
            marginTop: 4,
          }}
        >
          <Typography variant="h4" align="center" sx={{ mb: 2 }}>
            Where Problems Meet Creative Minds
          </Typography>
          <Typography sx={{ mb: 4 }}>
            Gripe emerged from a simple yet powerful idea: turning everyday
            frustrations into opportunities for innovation. At its heart, Gripe
            is a community-driven platform where challenges are shared and
            solutions are born. It's a space for voices to be heard and for
            collective problem-solving.
          </Typography>
          <Typography sx={{ mb: 4 }}>
            The concept of Gripe isn't just about venting; it's about fostering
            a culture of collaboration and creativity. We believe every problem,
            no matter how small, can spark an idea that makes a difference.
            Gripe is where these ideas find momentum and where people come
            together to make real change.
          </Typography>
          <Typography sx={{ mb: 4 }}>
            As the founder of Gripe, my goal was to create a platform that not
            only highlights personal concerns but also creates community bonds.
            It's a place where challenges are met with innovative thinking and
            where every member contributes.
          </Typography>
          <Box
            sx={{
              width: "200px",
              height: "200px",
              overflow: "hidden",
              borderRadius: "50%", // Make the image round
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={AlexPhoto}
              alt="Alex Rodriguez"
              style={{
                height: "auto",
                width: "auto",
                objectFit: "cover",
              }}
            />
          </Box>
          <Typography variant="h4" align="center" sx={{ mb: 2 }}>
            About Alex Rodriguez
          </Typography>
          <Typography sx={{ mb: 4 }}>
            Before embarking on the journey with Gripe, I founded Linked Lives,
            a service connecting seniors with local students for assistance with
            everyday tasks. This experience was a stepping stone, teaching me
            the value of community and the impact of technology in bringing
            people together.
          </Typography>
          <Typography sx={{ mb: 4 }}>
            My background as an engineer, 3D artist, and entrepreneur has always
            driven me to explore and create. From leading complex aerospace
            projects to designing wearable tech, my career has been a blend of
            innovation, leadership, and continuous learning.
          </Typography>
          <Typography sx={{ mb: 10 }}>
            Beyond my professional life, I am a father, a husband, and an
            advocate for connection. My passion lies in reimagining the concept
            of community living, moving away from isolation and towards a
            culture of shared experiences and purpose. My vision is to foster a
            society where happiness is derived not from material possessions,
            but from meaningful connections and a sense of belonging.
          </Typography>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default About;
