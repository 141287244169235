import React, { useState } from "react";
import { Box, Grid, Typography, Button, Container } from "@mui/material";
import HeroImage from "../../images/Hero.png"; // Ensure the path is correct
import WaitlistDialog from "./WaitlistDialog";

function Hero() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          position: "relative",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "center",
          alignItems: "flex-start",
          gap: 2,
          // top: { xs: "15vh", sm: "20vh" },
          mt: { xs: "15vh", sm: "20vh" },
          mb: "180px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            maxWidth: "600px",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "h4.fontSize", sm: "h2.fontSize" }, // Responsive font sizes
              fontWeight: { xs: "h4.fontWeight", sm: "h2.fontWeight" }, // Responsive font sizes
              lineHeight: "inherit",
            }}
            gutterBottom
          >
            {/* What are your biggest problems? */}
            Finally, a place to vent
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Gripe is a social network where people go to share their problems
            and innovators come to create solutions.
          </Typography>
          <WaitlistDialog />
        </Box>

        <Box
          sx={{
            display: "flex",
            width: { xs: "100%", sm: "300px" },
            justifyContent: "center",
          }}
        >
          <img
            src={HeroImage}
            alt="Hero"
            style={{
              width: "300px",
              height: "auto", // fixed height
              objectFit: "cover", // ensures the image covers the box area
            }}
          />
        </Box>
      </Box>
    </>
  );
}

export default Hero;
